import React from 'react';

import {
  PropertyDetailsResponseBody,
  PropertyStatus
} from '../../api/_base/generated/data-contracts';
import { EmptyColumnValue } from '../../helpers/constants';
import { squareFeetFormattedText, formattedPropertyPrice } from '../../utils/common.utils';
import { formatDate } from '../../utils/dateTime.utils';
import { ConditionalLink } from '../ContitionalLink';
import { HelpPopover } from '../HelpPopover';
import { BathIcon, BedIcon, FloorPlanIcon } from '../Icons';
import YearBuiltCalendar from '../Icons/YearBuiltCalendar';
import { ImageSlider, MakeFavoriteHeartView } from '../UI';
import { BuyBoxIconTag } from '../tags/BuyBoxIconTag';
import { PropertyTags } from '../tags/PropertyTags';

import styles from './MapPropertyCard.module.scss';

interface MapPropertyCardProps {
  property: PropertyDetailsResponseBody;
  showTags?: boolean;
}

export function MapPropertyCard({ property, showTags = true }: MapPropertyCardProps) {
  const propertyId = property.id;
  const propertyStatus = property.propertyStatus || PropertyStatus.PENDING;

  return (
    <ConditionalLink
      to={'/feed/' + propertyId}
      style={{ color: 'inherit', textDecoration: 'none' }}
      isLink={property.propertyStatus !== PropertyStatus.CLOSED}
    >
      <>
        <ImageSlider
          showThumbnails={false}
          showFullscreenButton={false}
          property={property}
          placeHolderClassName={styles.emptyImagePlaceholder}
        />

        <div className={styles.mapcard}>
          {showTags && (
            <PropertyTags
              status={propertyStatus}
              isExtendedListing={property.isExtendedListing}
              isStandardAuctionProperty={property.isStandardAuctionProperty}
              isHighestAndBestProperty={property.isHighestAndBestProperty}
              rounded
              small
            />
          )}
          <div className={styles.priceDescription}>
            {property.propertyStatus === PropertyStatus.CLOSED ? 'sold price' : 'asking price'}
          </div>
          <div>
            <span className={styles.price}>{formattedPropertyPrice(property)}</span>
          </div>
          <div className={styles.address}>
            {property.propertyStatus !== PropertyStatus.COMINGSOON && (
              <span className={styles.streetAddress}>{property.streetAddress} </span>
            )}
            <br />
            <span>
              {property.city}, {property.state} {property.zipCode}
            </span>
          </div>
          {property.propertyStatus !== PropertyStatus.CLOSED && (
            <div className={styles.heartShapedBox}>
              <BuyBoxIconTag className={styles.buyBoxIcon} property={property} />
              <MakeFavoriteHeartView className={styles.mapCardHeart} property={property} />
            </div>
          )}
          {property.propertyStatus === PropertyStatus.CLOSED && (
            <div className={styles.infoBox}>
              <HelpPopover>
                Closed sales shown represent properties that were listed by Sundae and sold on the
                Sundae Marketplace in the last twelve months. Sales prices displayed do not include
                any transaction fees.
              </HelpPopover>
            </div>
          )}
          <div className={styles.dateGroup}>
            {![PropertyStatus.PENDING, PropertyStatus.CLOSED].includes(property.propertyStatus!) &&
              property.projectedCloseDate && (
                <div className={styles.date}>
                  <div className={styles.dateLabel}>OFFER DUE</div>
                  <div>
                    {property.projectedCloseDate
                      ? formatDate(property.projectedCloseDate, undefined, undefined, 'L/d/yyyy')
                      : EmptyColumnValue}
                  </div>
                </div>
              )}
            {![PropertyStatus.PENDING, PropertyStatus.CLOSED].includes(property.propertyStatus!) &&
              property.closeOfEscrow && (
                <div className={styles.date}>
                  <div className={styles.dateLabel}>COE</div>

                  {property.closeOfEscrow
                    ? formatDate(property.closeOfEscrow, undefined, undefined, 'L/d/yyyy')
                    : EmptyColumnValue}
                </div>
              )}
            {property.propertyStatus === PropertyStatus.CLOSED && (
              <div className={styles.date}>
                SOLD{' '}
                {property.opportunity?.closingDate
                  ? formatDate(property.opportunity?.closingDate, undefined, undefined, 'L/d/yyyy')
                  : EmptyColumnValue}
              </div>
            )}
          </div>

          <div className={styles.mapCardSpecs}>
            <div>
              <BedIcon />
              {property.bedrooms}
            </div>
            <div>
              <BathIcon />
              {property.bathrooms}
            </div>
            <div>
              <FloorPlanIcon />

              {squareFeetFormattedText(property.squareFeet)}
            </div>
            <div>
              <YearBuiltCalendar />
              {property.yearBuilt}
            </div>
          </div>
        </div>
      </>
    </ConditionalLink>
  );
}
