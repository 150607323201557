import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTerritorySearchInterested } from '../api/public';
import { selectTerritorySearchMarkets, setTerritorySearchMarkets } from '../store/territory';

import { useIsUserAuthenticated } from './localStorage';

export const useTerritorySearch = () => {
  const isAuthenticated = useIsUserAuthenticated();
  const territorySearchMarkets = useSelector(selectTerritorySearchMarkets);
  const dispatch = useDispatch();

  const getAndCacheTerritorySearch = useCallback(async () => {
    const response = await getTerritorySearchInterested();
    const data = response.data;
    dispatch(setTerritorySearchMarkets(data));

    return data;
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      getAndCacheTerritorySearch();
    }
  }, [getAndCacheTerritorySearch, isAuthenticated]);

  return {
    territorySearchMarkets,
    getAndCacheTerritorySearch
  };
};
