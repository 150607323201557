import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';

export type GetOffersQuery = Exclude<
  Parameters<typeof api.propertyControllerListPropertyOffers>[0],
  undefined
>;

// GET /api/properties/offers
export const getOffers = (params: GetOffersQuery) => {
  return api.propertyControllerListPropertyOffers(params);
};

export const useOffers = (params: GetOffersQuery) => {
  return useQuery({ queryKey: ['getOffers'], queryFn: () => getOffers(params) });
};
