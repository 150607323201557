import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setOffers } from '../actions';
import { OfferDashboardSortBy, SortOrder } from '../api/_base/generated/data-contracts';
import { getOffers, GetOffersQuery } from '../api/properties/getOffers';

const queryParams: GetOffersQuery = {
  // page: 0,
  // size: 200,
  sortBy: OfferDashboardSortBy.OFFERDUEDATE,
  sortOrder: SortOrder.DESC
};

export const useUpdatePropertyOffers = () => {
  const dispatch = useDispatch();

  const updateAllOffers = useCallback(async () => {
    const offersResponse = await getOffers(queryParams);
    const offers = offersResponse.data;

    dispatch(
      setOffers({
        count: offers.totalActiveOffers,
        total: offers.totalActiveOfferSum,
        offers: offers.propertyOffers
      })
    );
  }, [dispatch]);

  useEffect(() => {
    updateAllOffers();
  }, [updateAllOffers]);

  return {
    updateAllOffers
  };
};
