import classNames from 'classnames';
import React from 'react';

import { SortOrder } from '../../api/_base/generated/data-contracts';
import { propertiesViewSortFields } from '../../helpers/marketPlaceViewHelpers';
import { MinimizeIcon } from '../Icons';

import styles from './PropertiesViewTableHeader.module.scss';
import { PropertiesViewTableHeaderProps } from './PropertiesViewTableHeaderTypes';

export const PropertiesViewTableHeader = ({
  queryParams,
  sortChange,
  sortFields = propertiesViewSortFields,
  cssName
}: PropertiesViewTableHeaderProps) => {
  return (
    <div className={classNames(styles.headerRow, cssName ? styles[cssName] : undefined)}>
      {sortFields.map((headerItem) => {
        const onClick = headerItem.disableSorting
          ? undefined
          : () => sortChange(headerItem.sortKey, headerItem.initialSortOrder);
        const text = !headerItem.leftAlign ? (
          headerItem.displayName
        ) : (
          <span className={styles.pl2m}>{headerItem.displayName}</span>
        );

        const className = classNames(styles.propertyHeaderColumn, {
          [styles.columnBath]: headerItem.cssName === 'Bath',
          [styles.columnBed]: headerItem.cssName === 'Bed',
          [styles.columnBlank]: headerItem.cssName === 'Blank',
          [styles.columnBuilt]: headerItem.cssName === 'Built',
          [styles.columnDistance]: headerItem.cssName === 'Distance',
          [styles.columnDue]: headerItem.cssName === 'Due',
          [styles.columnFavorite]: headerItem.cssName === 'Favorite',
          [styles.columnOffer]: headerItem.cssName === 'Offer',
          [styles.columnPrice]: headerItem.cssName === 'Price',
          [styles.columnSoldDate]: headerItem.cssName === 'SoldDate',
          [styles.columnSqft]: headerItem.cssName === 'Sqft',
          [styles.columnStatus]: headerItem.cssName === 'Status',
          [styles.columnSubmitted]: headerItem.cssName === 'Submitted',
          [styles.address]: headerItem.sortKey === 'propertyStatus',
          [styles.sortOrder]: queryParams.sortBy === headerItem.sortKey,
          [styles.propertyHeaderColumnLarge]: headerItem.size === 'large',
          [styles.propertyHeaderColumn]: headerItem.size === 'full',
          [styles.propertyHeaderColumnMedium]: headerItem.size === 'medium',
          [styles.propertyHeaderColumnSmall]: headerItem.size === 'small',
          [styles.hiddenTablet]: headerItem.hiddenTablet === true
        });

        return (
          <div key={headerItem.sortKey} onClick={onClick} className={className}>
            {text}
            {queryParams.sortBy === headerItem.sortKey && (
              <MinimizeIcon
                className={classNames(
                  styles.sortIcon,
                  styles.sortOrder,
                  styles[queryParams.sortOrder === SortOrder.ASC ? 'asc' : 'desc'],
                  {
                    [queryParams.sortOrder]: queryParams.sortBy === headerItem.sortKey
                  }
                )}
              />
            )}
          </div>
        );
      })}
      <div className={classNames(styles.propertyHeaderColumnMedium, styles.hiddenTablet)}></div>
    </div>
  );
};
