import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';

export type GetPropertiesQuery = Exclude<
  Parameters<typeof api.propertyControllerListProperties>[0],
  undefined
>;

// GET /api/properties
export const getProperties = (query: GetPropertiesQuery) => {
  return api.propertyControllerListProperties(query);
};

export const useProperties = (query: GetPropertiesQuery) => {
  return useQuery({ queryKey: ['getProperties'], queryFn: () => getProperties(query) });
};
