import classNames from 'classnames';
import React, { ReactNode } from 'react';

import InvestorContainer from '../../containers/InvestorContainer';
import { ViewportProvider } from '../../contexts/ViewportProvider';
import { useTerritorySearch } from '../../hooks/useTerritorySearch';

import styles from './Layout.module.scss';

interface LayoutProps {
  enableFullView?: boolean;
  children?: ReactNode;
  rightSiderbrarItems?: ReactNode;
}

export function Layout({ enableFullView, children }: LayoutProps) {
  useTerritorySearch();

  return (
    <InvestorContainer>
      <ViewportProvider>
        <div className={classNames(styles.pageHolder)}>
          <div
            className={classNames([
              styles.layoutWrap,
              { [styles.fullView]: enableFullView },
              { [styles.centerView]: !enableFullView }
            ])}
          >
            <div
              className={classNames([
                { [styles.fullView]: enableFullView },
                { [styles.centerCol]: !enableFullView }
              ])}
            >
              {children}
            </div>
          </div>
        </div>
      </ViewportProvider>
    </InvestorContainer>
  );
}
