import classNames from 'classnames';
import React from 'react';

import { DownloadAppBadge } from '../DownloadAppBadge';

import styles from './PrivateFooter.module.scss';

export const CondensedPrivateFooter = ({ isHiddenDesktop = false }) => {
  return (
    <footer
      role="contentinfo"
      className={classNames(styles.footer, styles.condensed, styles.container, {
        [styles.isHiddenDesktop]: isHiddenDesktop
      })}
    >
      <small>
        Sundae is a{' '}
        <a
          className={styles.link}
          target="_blank"
          href="https://sundae.com/disclosures/"
          rel="noopener noreferrer"
        >
          licensed broker
        </a>
        .
      </small>
    </footer>
  );
};

export function PrivateFooter() {
  return (
    <div className="container">
      <footer role="contentinfo" className={classNames(styles.footer, styles.container)}>
        {/* <DownloadAppBadge className={styles.badge} platform="iOS" /> */}

        <small className={styles.text}>
          While Sundae makes every effort to screen the investment opportunities displayed on our
          website, we can’t guarantee the condition of the property, the truth or accuracy of any
          third-party valuations, reports, or statements, or that any particular property represents
          a sound or advisable investment opportunity. All real estate investments are susceptible
          to substantial risks, including local, state and national market conditions, environmental
          hazards and disasters, undisclosed or unknown property conditions, zoning or land use
          changes, the availability of credit, and other risks of loss. Real estate investors who
          purchase the right to acquire real property from Sundae assume the entire risk of their
          investment, and are advised to independently investigate and verify the truth,
          completeness, and accuracy of all information regarding the property.
          <br />
          <br />
          Sundae is a{' '}
          <a
            className={styles.link}
            target="_blank"
            href="https://sundae.com/disclosures/"
            rel="noopener noreferrer"
          >
            licensed broker
          </a>
          . Texas Real Estate Commission:{' '}
          <a
            className={styles.link}
            target="_blank"
            href="https://sundae.com/wp-content/uploads/2022/10/Agency-Disclosure-IABS-1-0Texas.pdf"
            rel="noopener noreferrer"
          >
            Information About Brokerage Services
          </a>
          ,{' '}
          <a
            className={styles.link}
            target="_blank"
            href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN 1-4-1_1.pdf"
            rel="noopener noreferrer"
          >
            Consumer Protection Notice
          </a>
          . CA DRE 02088298. TX 9011255. 1-800-214-4426
          <br />
          Sundae Funding, Inc. offers loans for business purposes only and not for personal, family
          or household use.{' '}
          <a
            className={styles.link}
            target="_blank"
            href="https://sundae.com/disclosures/"
            rel="noopener noreferrer"
          >
            License and other information
          </a>
          . Sundae Funding, Inc. is a licensed finance lender with the California Department of
          Financial Protection and Innovation, CFL #60DBO-122336. Loans made or arranged pursuant to
          a California Financing Law license.
          <br />
          Sundae adheres to Fair Housing and Equal Housing Opportunity Laws.
        </small>
        <ul className={styles.inlineList}>
          <li>
            <a className={styles.link} target="_blank" href="/terms" rel="noopener noreferrer">
              Terms of Use
            </a>
          </li>
          <li>
            <a
              className={styles.link}
              target="_blank"
              href="https://sundae.com/privacy-policy/"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              className={styles.link}
              target="_blank"
              href="https://sundae.com/terms-of-service/"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </li>
        </ul>
        <small className={styles.copyright}>
          Copyright &copy; {new Date().getFullYear()} Sundae, Inc.
        </small>
      </footer>
    </div>
  );
}
