import { getPropertyDetails, getPropertyDetailsForMe, getPropertySupportUser } from './property/';

export type BooleanString = 'Yes' | 'No';

export const getDetail = async (propertyId: string, includeClosingInfo = false) => {
  const response = await getPropertyDetails(propertyId, includeClosingInfo);

  return response.data;
};

export const getSellerDetail = async (propertyId: string) => {
  const response = await getPropertyDetailsForMe(propertyId);

  return response.data;
};

export interface AccountSupportUserDetail {
  name: string;
  phone?: string | null;
  email?: string | null;
  photoUrl?: string | null;
  licenses: string[];
  isPrimary?: boolean;
}

export const getSupport = async (propertyId: string) => {
  const response = await getPropertySupportUser(propertyId);
  const responseData = response.data;

  return responseData.data;
};
