import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import {
  ListPropertiesResponseBodyItem,
  PropertyDetailsResponseBody,
  PropertyStatus
} from '../api/_base/generated/data-contracts';
import { getDetail, getSellerDetail } from '../api/property';

import { useHasMembership } from './useHasMembership';

export const usePropertyDetail = (): [
  PropertyDetailsResponseBody | undefined,
  (propertyId: string, property?: ListPropertiesResponseBodyItem) => void
] => {
  const [property, setProperty] = useState<PropertyDetailsResponseBody>();
  const { hasMembership } = useHasMembership();

  const loadPropertyDetail = useCallback(
    (propertyId: string, property?: ListPropertiesResponseBodyItem) => {
      setProperty(undefined);

      getDetail(propertyId, property?.propertyStatus === PropertyStatus.CLOSED)
        .then((response) => {
          setProperty(response);
        })
        .catch(() => {
          toast.error('Something went wrong, please try again');
        });
    },
    [setProperty, hasMembership]
  );

  return [property, loadPropertyDetail];
};

interface CombinedPropertyDetails
  extends Partial<ListPropertiesResponseBodyItem>,
    Partial<PropertyDetailsResponseBody> {}

export const useHomesNearMePropertyDetail = (): [
  CombinedPropertyDetails | undefined,
  (propertyId?: string, propertyListData?: ListPropertiesResponseBodyItem) => void,
  boolean
] => {
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState<CombinedPropertyDetails>();

  const loadPropertyDetail = useCallback(
    (propertyId?: string, propertyListData?: ListPropertiesResponseBodyItem) => {
      setLoading(true);

      if (propertyId && propertyListData) {
        getSellerDetail(propertyId)
          .then((response) => {
            setProperty({ ...response, ...propertyListData });
            setLoading(false);
          })
          .catch((e: unknown) => {
            setProperty(propertyListData);
            toast.error('Something went wrong, please try again');
            setLoading(false);
          });
      }
    },
    [setProperty]
  );

  return [property, loadPropertyDetail, loading];
};
